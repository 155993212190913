p {
  font-family: 'tone-regular';
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  color: #464646
}

a {
  text-decoration: none;
}

.white {
  color: #fff !important;
}

.align_center {
  text-align: center;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1445px;
  width: 1445px;
  margin: auto;
  padding-top: 50px;
}

.container {
  padding-left: 130px;
  padding-right: 130px;
  position: relative;
}

.background_wave {
  margin-top: 110px;
  margin-bottom: 50px;
  position: relative;
  min-height: 1050px;
  background-image: url(/src/images/background_wave.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 400px;

}

.container_title {
  font-family: 'tone-semibold';
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  color: #2D71B5;
  margin: 30px 0;
}

.flex {
  display: flex;
}

.logo {
  width: 193px;
  height: 193px;
}

.learn_for_future {
  max-width: 750px;
  margin-top: 90px;
}

.wave2 {
  width: 100%;
  position: absolute;
  top: -175px;
  z-index: 100;
}

.safe {
  position: absolute;
  top: 80px;
  left: 0px;
  z-index: 50;
}

.mode {
  position: absolute;
  top: 80px;
  right: 0px;
  z-index: 150;
}

.what_does {
  max-width: 517px;
  margin-top: -90px;
  margin-bottom: 90px;
}

.yelow_info {
  font-family: 'tone-semibold';
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: #F2C143;
  text-transform: uppercase;
}

.button {
  width: 518px;
  padding: 18px 18px 17px 18px;
  background-color: #F2C143;
  border-radius: 4px;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  margin-top: 30px;
}

.round {
  border-radius: 40px;
  margin: auto;
  margin-top: 50px;
  width: 544px;
}

.list_item {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.list_dot {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #F2C143;
  margin-right: 41px;
}

.list_text_container {
  background: #FBFBFB;
  box-shadow: -1px 4px 12px rgba(152, 152, 152, 0.25);
  border-radius: 4px;
  padding: 16px 36px 10px 36px;
  flex-grow: 1;
}

.card_list {
  padding-left: 130px;
  padding-right: 130px;
  display: flex;
  flex-wrap: wrap;
}

.card_item {
  width: 470px;
  padding: 10px 50px;
  background-color: #FBFBFB;
  box-shadow: -1px 4px 12px rgba(152, 152, 152, 0.25);
  border-radius: 30px;
  margin: 10px;
}

.card_title {
  font-size: 36px;
  line-height: 43px;
  color: #3075B4;
}

.card_with_list {
  width: 680px;
  padding: 10px 50px 30px 50px;
  background: #FBFBFB;
  box-shadow: -1px 4px 12px rgba(152, 152, 152, 0.25);
  border-radius: 30px;
  margin-top: 50px;
  display: inline-block;
}

.card_with_list_item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.yelow_triangle {
  margin-right: 20px;
}

.float_left {
  align-self: flex-end;
}

.float_right {
  align-self: flex-start;
}

.timer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.timer_item {
  height: 240px;
  width: 280px;
  padding-top: 70px;
  padding-bottom: 40px;
  text-align: center;
  background: linear-gradient(148.67deg, #3075B4 29.48%, #53A6A3 81.08%);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.time_value {
  font-size: 120px;
  line-height: 144px;
  color: #fff;
}

.time_label {
  font-size: 36px;
  line-height: 43px;
  color: #fff;
}

.acardion_container {
  margin-top: 20px;
  width: 100%;
}

.acardion_title {
  background: #FBFBFB;
  box-shadow: -1px 4px 12px rgba(152, 152, 152, 0.25);
  border-radius: 4px;
  padding: 36px 30px 30px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'tone-semibold';
  cursor: pointer;
  font-size: 24px;
}

.acardion_content {
  height: auto;
  overflow: hidden;
  display: block;
  transition: all .5s;
}

.acardion_content p {
  padding: 30px;
  font-size: 24px;
}

.acardion_content.close_acardion {
  height: 0px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 130px;
  background-color: #2D71B5;
  margin-top: 86px;
}

.links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.links a {
  margin-left: 24px;
}

.email {
  color: #fff
}