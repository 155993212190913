@font-face {
  font-family: 'tone-regular';
  src: url('/src//fonts/RFTone-Regular.ttf');
}

@font-face {
  font-family: 'tone-semibold';
  src: url('/src//fonts/RFTone-Semibold.ttf');
}

body {
  margin: 0;
  font-family: 'tone-regular';
}

button {
  outline: none
}
a {
  outline: none;
}